<script setup lang="ts"></script>
<template>
  <div>
    <div class="dark:bg-muted-800 flex min-h-screen bg-white">
      <div
        class="relative hidden w-0 flex-1 items-center justify-center lg:flex lg:w-full"
      >
        <div
          class="mx-auto w-full h-full flex items-center justify-center max-w-5xl"
        >
          <Logo height-class="h-52" />
        </div>
      </div>
      <div
        class="relative flex flex-1 flex-col justify-center px-6 py-12 lg:w-2/5 lg:flex-none"
      >
        <div
          class="dark:bg-muted-800 relative mx-auto w-full max-w-sm bg-white"
        >
          <!--Nav-->
          <div class="flex w-full items-center justify-between">
            <NuxtLink
              to="/dashboard"
              class="text-muted-400 hover:text-primary-500 flex items-center gap-2 font-sans font-medium transition-colors duration-300"
            >
              <Icon name="gg:arrow-long-left" class="h-5 w-5" />
              <span>Back to Home</span>
            </NuxtLink>
            <!--Theme button-->
            <BaseThemeToggle />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
